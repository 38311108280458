import * as React from "react"
import { graphql } from "gatsby"

import type { PageProps } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Topic from "../components/Topic"
import Showcase from "../components/Showcase"

type DataProps = {
  site: {
    siteMetadata: {
      title: string
      description: string
      author: string
    }
  }
}

const form = {
  text: `Signup form`,
  url: `https://docs.google.com/forms/d/e/1FAIpQLSdrQYgXXnn7IgiOclx4yGypbw-BquFFCjXIUoGh0eQROJPY9g/viewform`,
  description: `Sign up for the LAN. See you there!`,
}

const IndexPage = ({ data: { site } }: PageProps<DataProps>) => {
  return (
    <Layout
      title={site.siteMetadata.title}
      description={site.siteMetadata.description}
      author={site.siteMetadata.author}
    >
      <Seo
        title={site.siteMetadata.title}
        description={site.siteMetadata.description}
        author={site.siteMetadata.author}
      />
      <Topic title="The LAN" className="about nes-container is-dark">
        <p>
          <span className="nes-text is-success">May 5th 18:00 - May 7 13:00</span> there will be a LAN party in
          Bernoulliborg Study Lan(d)scape. The participation fee for this event is <span className="nes-text is-warning">€5,00</span>.  Optionally, there will be food. This is <span className="nes-text is-warning">€3,00</span> for Friday; <span className="nes-text is-warning">€6,50</span> for Saturday.
        </p>
        <a href={form.url}>
          <button type="button" className="nes-btn is-primary">
            Sign me up!
          </button>
        </a>
      </Topic>
      <Showcase title="Transportation">
        <p className="note nes-text">
          For this event, the LANcie provides a transport service for your computer and peripherals (limited to
          Groningen city limits). The predicted price of this service is{` `}
          <span className="nes-text is-warning">€4,00</span>. Please pack your equipment properly, as even in transit
          they are your responsibility.
          <br />
          <br /> You can opt in for transport in the sign-up form. If you have any other requests, questions or
          concerns, please contact{` `}
          <a className="nes-pointer" href="mailto:lancie.fmf.nl">
            lancie@fmf.nl
          </a>
        </p>
      </Showcase>
      <Showcase title="Packing List">
        When preparing to leave for the LAN, please bring the following:
        <div className="lists is-dark">
          <ul className="nes-list is-circle">
            <li>Computer</li>
            <li>Medication (if applicable)</li>
            <li>A good mood</li>
            <li>Network cable</li>
            <li>Power strip</li>
            <li>Cookies</li>
            <li>In case of pick-up: a towel for your computer monitor</li>
            <li>Necessary peripherals:</li>
          </ul>
          <ul className="nes-list is-disc">
            <li>Headset</li>
            <li>Mouse</li>
            <li>Mouse pad</li>
            <li>A single monitor</li>
          </ul>
        </div>
        <br />
        Please note that only <u>a single</u> monitor is permitted, and speakers will <u>not</u> be allowed during the
        event. It would also be most convenient if you could label your stuff.
      </Showcase>
      <Showcase title="Games & Tournaments">
        {/*TODO: Add games here*/}
        There will also be multiple tournaments to be hosted during the LAN. Such as a speedrunning event, CSGO, Smash
        bros & hunger games.
      </Showcase>
      <Showcase className="nes-text is-error">
        If you have any questions, please don&#39;t hesitate to contact{` `}
        <a className="nes-pointer" href="mailto:lancie.fmf.nl">
          lancie@fmf.nl
        </a>
      </Showcase>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
